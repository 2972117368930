import { useForm } from "react-hook-form";
import { useNavigate, Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import Inputfield from "../../../components/FormFields/InputField";
import ReactPhoneInput from "../../../components/FormFields/ReactPhoneInput";
import ReactSelectField from "../../../components/FormFields/ReactSelectField";
import { genderTypes, professionalType } from "../../../constants";
import { addVet, inviteVet } from "../../../actions/Users";
import {getTimeSlots} from "../../../actions/timeSlot";
import { useEffect, useState } from "react";
import ImageUploaderField from "../../../components/FormFields/ImageUploaderField";

var countryList = require("country-data-list").countries;

function InviteVet() {

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { isSubmitting },
  } = useForm();


  const [slots, setSlots] = useState([]);

  let schedule = [
    {
        "frequency": [
            "1"
        ],
        "type": 1,
        "timings": [
            {
                "startTime": "540",
                "endTime": "1020"
            }
        ],
        "groupId": 5,
        "title": "Working Hours",
        "allDay": 0,
        "startDate": "2023-02-21T10:26:07.501Z"
    }
];


let returnData = {
    "workinghr": [
      {
        "frequency": "1",
        "type": 1,
        "repeatType": "never",
        "timings": [
          {
            "startTime": "540",
            "endTime": "1020"
          }
        ]
      }
    ]
  };

  const navigate = useNavigate();

  const getAllSlots = async () => {
    try {
      const resp = await getTimeSlots();
      setSlots(resp?.data?.responseData?.help);
    } catch ({ response, request }) {
      
    }
  };

  useEffect(() => {
    getAllSlots();
  }, []);

  // on form submissions
  const onSubmit = async(formData) => {
    console.log('___________',formData);
    try {
      await inviteVet({ ...formData});
      navigate("/dashboard/vets");
    } catch ({ response, request }) {
      if (response) {
        toast.error(response?.data?.message);
      } else if (request) {
        toast.error("You do not have internet connection");
      }
    }
  };

  return (
    <>
      <div className="container ani-ui-block shop-manager">
        <div className="row menu-top-block">

          <div className="data-header-left cr-operators">
            {/* <div className="count-data">
              <span className="pr-page"><Link to="/dashboard/vets" className="link-hrf">Vets </Link> </span>
            </div> */}
            {/* <div className="count-day lk-path">
              <span className="st-current">Add Vet</span>
            </div> */}
          </div>



          <div className="col-sm-12 tl-block align-self-center">
            <h4 className="heading">Invite Veterinarian</h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-lg-12 col-md-12">
                <div className="add-left-block">
                  <div className="fields-ui-block">
                    <div className="basic-details">
                      <div className="heading">
                        <h4>Personal Informations</h4>
                      </div>
                      <div className="form-block">
                        <div className="row">
                          <div className="col-lg-4">
                            <Inputfield
                              control={control}
                              label="Email"
                              normalize={(e) => e.replace(/^\s+/g, "")}
                              name="email"
                              type="text"
                              fieldClass="form-control"
                              rules={{
                                required: {
                                  value: true,
                                  message: "Email is required",
                                },
                                pattern: {
                                  value:
                                    /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
                                  message: "Please enter valid email address",
                                },
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row save-button-block">
                    <div className="col-sm-12 align-self-center">
                      <div className="btn-wrpr">
                        <button
                          type="submit"
                          disabled={isSubmitting}
                          className={`btn-full orange-btn ${
                            isSubmitting && "btn-loader"
                          }`}
                        >
                          Sent Invite
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default InviteVet;
