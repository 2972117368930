import axios from "axios";


export function sendMoreDetailToVet(payload) {
    return axios({
        method: 'POST',
        data: payload,
        url: 'user/onboard-note'
    })
}

export function getNotes(parmas) {
    return axios({
        method: 'GET',
        params: parmas,
        url: 'user/onboard-note'
    })
}

export function updateUserOnboardStatus(payload) {
    return axios({
        method: 'PATCH',
        data: payload,
        url: 'user/onboard'
    })
}

export function getUsersOrVets(params) {
    return axios({
        method: 'GET',
        params: params,
        url: 'admin/users'
    })
}

export function downloadUsersOrVetsCsv(params) {
    return axios({
        method: 'GET',
        params: params,
        url: 'admin/users'
    })
}

export function updateUser(params) {
    return axios({
        method: 'PATCH',
        data: params,
        url: 'admin/updateUser'
    })
}

export function addVet(params) {
    return axios({
        method: 'POST',
        data: params,
        url: '/clinic-user'
    })
}

export function inviteVet(params) {
    return axios({
        method: 'POST',
        data: params,
        url: '/invite-vet'
    })
}

export function getClinics(params) {
    return axios({
        method: 'GET',
        params: params,
        url: 'clinics'
    })
}

export function addClinicStaff(params) {
    return axios({
        method: 'POST',
        data: params,
        url: '/clinic-member'
    })
}

export function getClinicStaff(params) {
    return axios({
        method: 'GET',
        params: params,
        url: '/clinic-member'
    })
}

export function getTransactionList(params) {
    return axios({
        method: 'GET',
        params: params,
        url: '/transactions-list'
    })
}

export function getUserPetDetail(params) {
    return axios({
        method: 'GET',
        params: params,
        url: '/pet'
    })
}

export function sendEmail(params) {
    return axios({
        method: 'POST',
        data: params,
        url: '/send-email'
    })
}


export function deleteClinicMember(payload) {
    return axios({
        method: 'DELETE',
        params: payload,
        url: '/clinic-member'
    })
}

export function getSettings(params) {
    return axios({
        method: 'GET',
        params: params,
        url: '/admin/setting'
    })
}

export function saveSettngs(params) {
    return axios({
        method: 'PATCH',
        data: params,
        url: '/admin/setting'
    })
}
